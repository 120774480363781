import request from '@/api/request.js'

/**
 * @Description: 货品库存
 */

// 分页查询
export function pageWmsStock(data) {
    return request({
        url: '/api/wmsStock/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsStock(data) {
    return request({
        url: '/api/wmsStock/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsStock(data) {
    return request({
        url: '/api/wmsStock/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsStock(data) {
    return request({
        url: '/api/wmsStock/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsStock(data) {
    return request({
        url: '/api/wmsStock/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsStock(data) {
    return request({
        url: '/api/wmsStock/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsStock(data) {
    return request({
        url: '/api/wmsStock/batchDelete',
        method: 'POST',
        data
    })
}

