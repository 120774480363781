<template>
  <div>
    <div class="contents">
      <submenu curbg=0></submenu>
      <div class="tableSearch">
          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="90px">
                <el-form-item label="货品" style="margin-bottom:10px">

                  <el-input v-model="searchForm.skuName" clearable  style="width:190px"
                    placeholder="请输入货品"></el-input>
                </el-form-item>

                <el-form-item label="货品编码" style="margin-bottom:10px">

                  <el-input v-model="searchForm.skuCode" clearable  style="width:190px"
                    placeholder="请输入货品编码"></el-input>
                </el-form-item>

                <el-form-item label="仓库" style="margin-bottom:10px">

                  <el-input v-model="searchForm.warehouseName" clearable  style="width:190px"
                    placeholder="请输入仓库"></el-input>
                </el-form-item>

                <el-form-item label="客户" style="margin-bottom:10px">

                  <el-input v-model="searchForm.customerName" clearable  style="width:190px"
                    placeholder="请输入客户"></el-input>
                </el-form-item>

                <el-form-item label="货位" style="margin-bottom:10px">

                  <el-input v-model="searchForm.binName" clearable  style="width:190px"
                    placeholder="请输入货位"></el-input>
                </el-form-item>

                <span class="buttons">
                    <el-button @click="createSearch" type="primary" icon="el-icon-search" :loading="loading">查询</el-button>
                    <el-button @click="reset">重置</el-button>
                </span>
          </el-form>
      </div>

    <div class="mainbox">

     <vxe-toolbar ref="xToolbar" custom>
            <template #buttons>

            </template>
            <template #tools>
            </template>
      </vxe-toolbar>

      <vxe-table border
        id="table"
        empty-text="没有更多数据"
        header-align="center"
        stripe
        :data="tableData"
        :custom-config="{storage: true, resizable: true}"
        :column-config="{resizable: true}"
        :row-config="{isCurrent: true, isHover: true, keyField: 'id'}"
        size="small"
        @checkbox-all="handleSelectionChange"
        @checkbox-change="handleSelectionChange"
         ref="table">
          <vxe-column type="checkbox" width="40" fixed></vxe-column>


        <vxe-column
            field="skuName"
            title="货品"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.skuName}}
          </template>
        </vxe-column>
        <vxe-column
            field="skuSpec"
            title="规格"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.skuSpec}}
          </template>
        </vxe-column>
        <vxe-column
            field="skuCode"
            title="货品编码"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.skuCode}}
          </template>
        </vxe-column>
        <vxe-column
            field="unitName"
            title="库存单位"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.unitName}}
          </template>
        </vxe-column>
        <vxe-column
            field="quantity"
            title="可用库存"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.quantity}}
          </template>
        </vxe-column>
        <vxe-column
            field="lockQuantity"
            title="锁定库存"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.lockQuantity}}
          </template>
        </vxe-column>
        <vxe-column
            field="totalQuantity"
            title="总库存数"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.totalQuantity}}
          </template>
        </vxe-column>
        <vxe-column
            field="warehouseName"
            title="仓库"
            min-width="120"
        >
          <template slot-scope="scope">
                {{scope.row.warehouseName}}
          </template>
        </vxe-column>
        <vxe-column
            field="customerName"
            title="客户"
            min-width="120"
        >
          <template slot-scope="scope">
                {{scope.row.customerName}}
          </template>
        </vxe-column>
        <vxe-column
            field="binName"
            title="货位"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.binName}}
          </template>
        </vxe-column>        

      </vxe-table>

      <div class="paginations">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes"
            :page-sizes="[20, 50, 100, 200, 400]"
            @current-change="changePage" :current-page="page" :page-size="pageSize" @size-change="handleSizeChange"
            :total="total">
          </el-pagination>
      </div>
      </div>
    </div>

    <stock-add ref="wmsStockAdd" @success="getTopSearch"></stock-add>
    <stock-detail ref="wmsStockDetail" @success="getTopSearch"></stock-detail>
    <stock-edit ref="wmsStockEdit" @success="getTopSearch"></stock-edit>

  </div>
</template>

<script>
import Vue from 'vue'
import Clipboard from 'clipboard'
import submenu from "@/components/submenu"
import backImg from "@/assets/logofang.png"
import stockAdd from "./wmsStockAdd"
import stockDetail from "./wmsStockDetail"
import stockEdit from "./wmsStockEdit"

import { pageWmsStock, detailWmsStock, delWmsStock, batchDeleteWmsStock } from "@/api/wms/wmsStock"
import { getEnums } from "@/api/common"

let moment = require('moment')
Vue.prototype.Clipboard = Clipboard

export default {
  name: 'WmsStock',
  components: { submenu, stockAdd, stockDetail, stockEdit },
  data() {
    return {
      searchForm: {
      },
      total: 0,
      page: 1,
      counts: 0,
      pageSize: 20,
      tableData: [],
      backImg: backImg,
      enums: {
      },
      enumMaps: {},
      loading: false,
      multipleSelection: [],
    }
  },
  methods: {
    initEnums() {
        const param = {
            names: Object.keys(this.enums)
        }
        getEnums(param).then(res=>{
            if(res.code===200) {
              this.enums = res.data.enums
              Object.keys(this.enums).forEach(key => {
                let map = {}
                this.enums[key].forEach(obj => map[obj.value] = obj.name)
                this.enumMaps[key] = map
              })
            } else {
                this.$message.error(res.message)
            }
        })
    },
    handleCommand(arg) {
        console.log("handleCommand", arg)
        if(arg == "batchDelete") {
            return this.handleBatchDelete()
        }
    },
    handleBatchDelete() {
        console.log("handleBatchDelete", this.multipleSelection)
        if(this.multipleSelection.length ==0) {
            return this.$message.warning("请选择要批量删除的记录")
        }
        this.$confirm('确定要删除这'+ this.multipleSelection.length +'条数据吗，一旦删除不可恢复，请谨慎操作?', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                 const param = {
                    ids: this.multipleSelection.map(it => it.id)
                 }
                 batchDeleteWmsStock(param).then(res => {
                    if(res.code===200) {
                        this.$message.success(res.message)
                        this.getTopSearch()
                    } else {
                        this.$message.error(res.message)
                    }
                 })
        }).catch(err=>{})
    },
    handleAdd() {
      this.$refs.wmsStockAdd.onOpen()
    },
    handleDetail(row) {
      this.$refs.wmsStockDetail.onOpen(row)
    },
    handleEdit(row) {
      this.$refs.wmsStockEdit.onOpen(row)
    },
    handleSelectionChange(val) {
        console.log("handleSelectionChange", val)
      this.multipleSelection = val.records
    },
    del(row) {
      const param = {
          id: row.id
      }
      del(param).then(res=>{
          if(res.code===200) {
            this.$message.success(res.message)
            this.getTopSearch()
          } else {
            this.$message.error(res.message)
          }
      })
    },
    handleSizeChange(val){
      this.pageSize = val
      this.getTopSearch()
    },
    createSearch() {
      this.page = 1
      this.getTopSearch()
    },
    reset(){
      this.searchForm={
        date: ''
      }
      this.page = 1
      this.getTopSearch()
    },

    getTopSearch(){
      var that = this
      var param = that.searchForm
      param.pageNo = that.page
      param.pageSize = that.pageSize
      param.orderByCreated = "DESC"
      that.loading = true
      pageWmsStock(param).then(res => {
        that.loading = false
        if(res.code ===200) {
          var nodataArr = res.data.rows || []
          that.tableData = nodataArr
          that.total = res.data.totalRows
          that.counts = res.data.counts
        }else{
          this.$message.error(res.message)
        }
      }).catch(err=>{
        that.loading = false
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
  },
  created() {
    this.initEnums()
    this.getTopSearch()
  },
  mounted(){
  }
}
</script>
<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}
.el-select {
    width: 190px;
}
.red {
  color: rgb(236, 78, 15);
}
.c-button{
  margin-left:8px;
}
.tbBtn {
  margin-right:10px;
}
.buttons {
}
</style>