<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @close="onClose"
    title="货品库存详情"
    :visible.sync="visible"
    width="600px"
    >

      <el-descriptions class="margin-top" :column="1"
      border>


            <el-descriptions-item label="ID">
            {{ row.id }}
            </el-descriptions-item>


            <el-descriptions-item label="货品">
            {{ row.skuName }}
            </el-descriptions-item>

            <el-descriptions-item label="规格">
            {{ row.skuSpec }}
            </el-descriptions-item>

            <el-descriptions-item label="货品编码">
            {{ row.skuCode }}
            </el-descriptions-item>


            <el-descriptions-item label="库存单位">
            {{ row.unitName }}
            </el-descriptions-item>

            <el-descriptions-item label="可用库存">
            {{ row.quantity }}
            </el-descriptions-item>

            <el-descriptions-item label="锁定库存">
            {{ row.lockQuantity }}
            </el-descriptions-item>

            <el-descriptions-item label="总库存数">
            {{ row.totalQuantity }}
            </el-descriptions-item>


            <el-descriptions-item label="仓库">
            {{ row.warehouseName }}
            </el-descriptions-item>


            <el-descriptions-item label="客户">
            {{ row.customerName }}
            </el-descriptions-item>


            <el-descriptions-item label="货位">
            {{ row.binName }}
            </el-descriptions-item>

            <el-descriptions-item label="备注">
            {{ row.remark }}
            </el-descriptions-item>


            <el-descriptions-item label="创建时间">
            {{ row.createTime }}
            </el-descriptions-item>




      </el-descriptions>


      <div slot="footer">
        <el-button @click="close">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums } from "@/api/common"
import { detailWmsStock } from "@/api/wms/wmsStock"

export default {
  inheritAttrs: false,
  components: {},
  props: [],
  data() {
    return {
        visible: false,
        row: {
            id: '',
            skuId: '',
            skuName: '',
            skuSpec: '',
            skuCode: '',
            unitId: '',
            unitName: '',
            quantity: '',
            lockQuantity: '',
            totalQuantity: '',
            warehouseId: '',
            warehouseName: '',
            customerId: '',
            customerName: '',
            binId: '',
            binName: '',
            remark: '',
            createTime: '',
        },
        enums: {},
        enumMaps: {},
    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    detail() {
        const param = {
            id: this.row.id
        }
        detailWmsStock(param).then(res=>{
          if(res.code === 200) {
            this.row = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    },
    onOpen(row) {
        console.log("onOpen", row)
        this.visible = true
        this.row = row
        this.detail()
    },
    onClose() {
    },
    close() {
      this.visible = false
    },
  }
}

</script>
<style scoped>
.avatar {
    max-width: 200px;
    max-height: 200px
}
</style>
