
<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners"  @close="onClose"
    title="编辑货品库存"
    :visible.sync="visible"
    width="600px"
    >

      <el-form ref="form" :model="row" :rules="rules" size="mini" label-width="120px"      >
<el-row>
        
        <el-col :span="24">
                <el-form-item label="货品" prop="skuId">
              <el-input v-model="row.skuId" placeholder="请输入货品" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="货品" prop="skuName">
              <el-input v-model="row.skuName" placeholder="请输入货品" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="规格" prop="skuSpec">
              <el-input v-model="row.skuSpec" placeholder="请输入规格" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="货品编码" prop="skuCode">
              <el-input v-model="row.skuCode" placeholder="请输入货品编码" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="库存单位" prop="unitId">
              <el-input v-model="row.unitId" placeholder="请输入库存单位" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="库存单位" prop="unitName">
              <el-input v-model="row.unitName" placeholder="请输入库存单位" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="可用库存" prop="quantity">
              <el-input-number v-model="row.quantity"
                :disabled="false"
                placeholder="请输入可用库存" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="锁定库存" prop="lockQuantity">
              <el-input-number v-model="row.lockQuantity"
                :disabled="false"
                placeholder="请输入锁定库存" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="总库存数" prop="totalQuantity">
              <el-input-number v-model="row.totalQuantity"
                :disabled="false"
                placeholder="请输入总库存数" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="仓库id" prop="warehouseId">
              <el-input v-model="row.warehouseId" placeholder="请输入仓库id" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="仓库" prop="warehouseName">
              <el-input v-model="row.warehouseName" placeholder="请输入仓库" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="客户ID" prop="customerId">
              <el-input v-model="row.customerId" placeholder="请输入客户ID" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="客户" prop="customerName">
              <el-input v-model="row.customerName" placeholder="请输入客户" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="货位" prop="binId">
              <el-input v-model="row.binId" placeholder="请输入货位" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="备注" prop="remark">
              <el-input v-model="row.remark" placeholder="请输入备注" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        </el-row>

      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums, uploadFile } from "@/api/common"
import { editWmsStock } from "@/api/wms/wmsStock"


export default {
  inheritAttrs: false,
  components: { },
  props: [],
  data() {
    return {
      visible: false,
      row: {
            skuId: null,
            skuName: null,
            skuSpec: null,
            skuCode: null,
            unitId: null,
            unitName: null,
            quantity: null,
            lockQuantity: null,
            totalQuantity: null,
            warehouseId: null,
            warehouseName: null,
            customerId: null,
            customerName: null,
            binId: null,
            binName: null,
            remark: null,
      },
      rules: {
            lockQuantity: [
                { required: true, message: '请输入锁定库存!'},
            ],
            totalQuantity: [
                { required: true, message: '请输入总库存数!'},
            ],
      },
        enums: {},
        enumMaps: {},
    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    onOpen(row) {
        this.visible = true
        this.row = row
    },
    onClose() {
    },
    close() {
      this.$refs['form'].resetFields()
      this.visible = false
    },
    success(result) {
      this.$emit('success', result)
    },
    upload(param){
        const formData = new FormData()
        formData.append('file', param.file)
        uploadFile(formData).then(res => {
            if(res.code === 200){
                param.onSuccess(res.data.url)
                this.$message.success("上传成功")
            }else{
                this.$message.error(res.message)
            }
        }).catch(response => {
            console.log(response)
            this.$message.error('上传失败')
            param.onError()
        })
    },
    handleConfirm() {
      this.$refs['form'].validate(valid => {
        if (!valid) return;
        const param = this.row
        this.loading = true
        editWmsStock(param).then(res=>{
            this.loading = false
            if(res.code===200) {
                this.$message.success(res.message)
                this.success(res.data)
                this.close()
            } else {
                this.$message.error(res.message)
            }
        }).catch(err=>{
            this.loading = false
        })
      })
    },
  }
}

</script>
<style scoped>
.el-select {
    width: 200px;
}
.avatar {
    max-width: 200px;
    max-height: 200px
}
</style>
